// extracted by mini-css-extract-plugin
export var arGradientBlue = "ArQuickLinks-module--ar-gradient-blue--kaFLq";
export var arGradientLight = "ArQuickLinks-module--ar-gradient-light--3kjKt";
export var arH1 = "ArQuickLinks-module--ar-h1--Rx9QW";
export var arH1Mobile = "ArQuickLinks-module--ar-h1-mobile--UFCId";
export var arH2 = "ArQuickLinks-module--ar-h2--o+Qqq";
export var arH2Mobile = "ArQuickLinks-module--ar-h2-mobile--1AoaC";
export var arH3 = "ArQuickLinks-module--ar-h3--eFhdF";
export var arH3Mobile = "ArQuickLinks-module--ar-h3-mobile--qATUG";
export var arH4 = "ArQuickLinks-module--ar-h4--xzmAU";
export var arH4Mobile = "ArQuickLinks-module--ar-h4-mobile--pW2ku";
export var arH5 = "ArQuickLinks-module--ar-h5--JGqh4";
export var arH5Mobile = "ArQuickLinks-module--ar-h5-mobile--zF57J";
export var arLinkDesktop = "ArQuickLinks-module--ar-link-desktop--jip35";
export var arLinkMobile = "ArQuickLinks-module--ar-link-mobile--EWBY8";
export var arParagraphBase = "ArQuickLinks-module--ar-paragraph-base--biF6N";
export var arParagraphBaseMedium = "ArQuickLinks-module--ar-paragraph-base-medium--ZurnZ";
export var arParagraphSmall = "ArQuickLinks-module--ar-paragraph-small--9xU+P";
export var arParagraphSmallMedium = "ArQuickLinks-module--ar-paragraph-small-medium--MgfNF";
export var arParagraphXl = "ArQuickLinks-module--ar-paragraph-xl--FIfSq";
export var arParagraphXlMedium = "ArQuickLinks-module--ar-paragraph-xl-medium--+bMb+";
export var arParagraphXsmall = "ArQuickLinks-module--ar-paragraph-xsmall--ALpSn";
export var arParagraphXsmallMedium = "ArQuickLinks-module--ar-paragraph-xsmall-medium--hOw3k";
export var arQuote = "ArQuickLinks-module--ar-quote--K0BQQ";
export var arQuoteMobile = "ArQuickLinks-module--ar-quote-mobile--2wH9k";
export var arTransition = "ArQuickLinks-module--ar-transition--IHquS";
export var container = "ArQuickLinks-module--container--LNXyu";